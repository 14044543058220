body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.MuiFormHelperText-root {
  color: red !important;
}
.MuiDateRangeCalendar-monthContainer {
  border-right: 0 !important;
}
.MuiDayCalendar-weekDayLabel {
  font-size: 15px !important;
  color: #535350 !important;
}
.MuiTypography-subtitle1 {
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #000a14 !important;
}

.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight:last-of-type {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight:first-of-type {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-startOfMonth.MuiDateRangePickerDay-firstVisibleCell {
  border-radius: 0 !important;
}

.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-endOfMonth.MuiDateRangePickerDay-lastVisibleCell {
  border-radius: 0 !important;
}
button[data-position="start"] {
  border-radius: 4px 0px 0px 4px !important;
}

button[data-position="end"] {
  border-radius: 0px 4px 4px 0px !important;
}
